<template>
  <div id="page-container" class="bom-category page-container">
    <div ref="toolbar" class="toolbar">
      <el-row>
        <el-col :span="12">
          <el-button type="primary" @click="addCategory">新增</el-button>
          <el-button type="primary" @click="deleteCategory">删除</el-button>
          <!-- <el-button type="primary" @click="editCategory">编辑</el-button> -->
        </el-col>
      </el-row>
    </div>
    <el-table
      ref="workshopTable"
      :data="dataList"
      row-key="id"
      border
      height="600"
      :cell-class-name="setClass"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="40" :reserve-selection="true" />
      <el-table-column align="center" header-align="center" type="index" label="序号" width="50" />
      <el-table-column header-align="center" align="left" prop="name" label="元件分类" width="200">
        <template slot-scope="scope">
          <el-input v-model="scope.row.name" size="mini" :max-length="100" clearable />
        </template>
      </el-table-column>

      <el-table-column header-align="center" align="center" width="1000" label="条件">
        <template slot-scope="scope">
          <div
            v-for="(element, key) in scope.row.bomMatchingInfo"
            :key="key"
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              border-bottom: 1px solid #dcdfe6 !important;
              width: 100%;
            "
          >
            <div style="width: 180px; padding: 10px">
              <el-input v-model="element.name" size="mini" :max-length="100" clearable />
            </div>
            <div
              style="
                display: flex;
                flex-direction: row;
                border-left: 1px solid #dcdfe6 !important;
                flex: 1;
                width: 80px;
                padding: 10px;
              "
            >
              单价：
              <el-input
                v-model="element.price"
                style="width: 80px"
                size="mini"
                :max-length="100"
                clearable
              />
            </div>
            <div
              style="
                display: flex;
                width: 500px;
                flex-direction: column;
                border-left: 1px solid #dcdfe6 !important;
                border-right: 1px solid #dcdfe6 !important;
                flex: 1;
              "
            >
              <div
                v-for="(item, index) in element.bomMatchingAcls"
                :key="index"
                style="
                  display: flex;
                  padding-bottom: 10px;
                  padding-top: 10px;
                  border-bottom: 1px solid #dcdfe6 !important;
                "
              >
                <div style="width: 90px">
                  <el-select
                    ref="projectType"
                    v-model="item.logical"
                    size="mini"
                    :style="{ width: '80px' }"
                    allow-create
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="(item1, index1) in matchingType"
                      :key="index1"
                      :label="item1.label"
                      :value="item1.label"
                    >
                      {{ item1.label }}
                    </el-option>
                  </el-select>
                </div>
                <div style="padding-left: 30px">
                  <el-select
                    ref="projectType"
                    v-model="item.param"
                    size="mini"
                    :style="{ width: '120px' }"
                    allow-create
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="(item1, index1) in typeList"
                      :key="index1"
                      :label="item1.label"
                      :value="item1.label"
                    >
                      {{ item1.label }}
                    </el-option>
                  </el-select>
                </div>
                <div style="padding-left: 30px">
                  <el-select
                    ref="projectType"
                    v-model="item.comparison"
                    size="mini"
                    :style="{ width: '140px' }"
                    allow-create
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="(item1, index1) in aclsList"
                      :key="index1"
                      :label="item1.label"
                      :value="item1.key"
                    >
                      {{ item1.label }}
                    </el-option>
                  </el-select>
                  <div style="display: flex; padding-top: 10px">
                    <span>值：</span>
                    <el-input
                      v-model="item.data"
                      :style="{ width: '110px' }"
                      size="mini"
                      :max-length="100"
                      clearable
                    />
                  </div>
                </div>
                <div style="padding-left: 30px; padding-right: 30px">
                  <el-button
                    type="text"
                    icon="el-icon-delete"
                    title="删除"
                    @click="deleteAcls(element.bomMatchingAcls, index)"
                  ></el-button>
                </div>
              </div>
              <div style="width: 500px">
                <el-button
                  type="text"
                  icon="el-icon-circle-plus"
                  title="增加"
                  @click="addAcls(element.bomMatchingAcls, element, element.bomMatchingAcls.length)"
                ></el-button>
              </div>
            </div>
            <div style="width: 90px">
              <el-button
                type="text"
                icon="el-icon-delete"
                title="删除"
                @click="deleteMatching(scope.row.bomMatchingInfo, key)"
                >删除</el-button
              >
            </div>
          </div>
          <div>
            <el-button
              type="text"
              icon="el-icon-plus"
              title="增加"
              @click="addMatching(scope.row.bomMatchingInfo, scope.row)"
              >增加</el-button
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="left"
        prop="matchingKw"
        label="关键词"
        width="400"
      >
        <template slot-scope="scope">
          <div>
            <div>
              <span style="padding-right: 100px">匹配关键词：</span>
              <span>是否统计数量：</span>
            </div>
            <div
              v-for="(item, index) in scope.row.bomMatchingKwInfo"
              :key="index"
              style="display: flex"
            >
              <div style="padding-right: 5px">
                <el-input v-model="item.name" size="mini" :max-length="100" clearable />
              </div>
              <div style="padding-bottom: 5px">
                <el-select
                  ref="projectType"
                  v-model="item.isCount"
                  size="mini"
                  :style="{ width: '120px' }"
                  allow-create
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item1, index1) in kwIsCountType"
                    :key="index1"
                    :label="item1.label"
                    :value="item1.key"
                  >
                    {{ item1.label }}
                  </el-option>
                </el-select>
              </div>
              <div>
                <el-button
                  type="text"
                  icon="el-icon-delete"
                  title="删除"
                  @click="deleteMatchingKw(scope.row.bomMatchingKwInfo, index)"
                ></el-button>
              </div>
            </div>
            <el-button
              type="text"
              icon="el-icon-plus"
              title="增加"
              @click="addMatchingKw(scope.row.bomMatchingKwInfo, scope.row)"
              >增加</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-row style="padding-top: 10px">
      <el-col :span="12">
        <el-button style="float: right" type="primary" @click="save">保存</el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import BomCategory from "@/api/bom-category";

export default {
  name: "BomCabegory",
  components: {},
  props: {},
  data() {
    return {
      dataList: [],
      selectList: [],
      value: "",
      isEdit: false,
      matchingType: [
        {
          label: "and",
          key: "and",
        },
        {
          label: "or",
          key: "or",
        },
      ],
      typeList: [
        {
          label: "额定电流",
          key: "1",
        },
        {
          label: "额定电流规格",
          key: "1",
        },
        {
          label: "壳架电流",
          key: "2",
        },
        {
          label: "定额",
          key: "3",
        },
        {
          label: "试验等候",
          key: "4",
        },
        {
          label: "额定容量",
          key: "5",
        },
        {
          label: "一次额定电流",
          key: "6",
        },
        {
          label: "功率",
          key: "7",
        },
        {
          label: "级数",
          key: "8",
        },
        {
          label: "线圈电压",
          key: "9",
        },
        {
          label: "额定电压",
          key: "10",
        },
      ],
      aclsList: [
        {
          label: "等于",
          key: "==",
        },
        {
          label: "不等于",
          key: "!=",
        },
        {
          label: "包含",
          key: "=~",
        },
        {
          label: "不包含",
          key: "!~",
        },
        {
          label: "大于",
          key: "gt",
        },
        {
          label: "小于",
          key: "lt",
        },
        {
          label: "大于或等于",
          key: "ge",
        },
        {
          label: "小于或等于",
          key: "le",
        },
        {
          label: "AND",
          key: "and",
        },
        {
          label: "OR",
          key: "or",
        },
      ],

      kwIsCountType: [
        {
          label: "是",
          key: 0,
        },
        {
          label: "否",
          key: 1,
        },
      ],
    };
  },
  watch: {},
  mounted() {
    this.getBomCategory();
  },
  methods: {
    getBomCategory() {
      this.axios.post(BomCategory.getBomCategory, {}).then((data) => {
        this.dataList = data;
      });
    },
    filterData() {},
    setEdited() {},
    deleteRow(row, key) {
      row.bomParamValueInfo.splice(key, 1);
    },
    addMatching(row, bomCategory) {
      row.push({
        name: bomCategory.name,
        bomCategoryId: bomCategory.id,
        status: 1,
        bomMatchingAcls: [
          {
            logical: "and",
            param: "额定电流",
            comparison: "==",
            data: 120,
            matchingId: row.id,
            executionorder: 0,
          },
        ],
        bomMatchingKwInfo: [],
      });
    },
    deleteMatching(bomMatchingInfo, bomCategory) {
      if (bomMatchingInfo[bomCategory].id != null && bomMatchingInfo[bomCategory].id !== "") {
        this.axios.post(BomCategory.deleteMatching, bomMatchingInfo[bomCategory]).then((data) => {
          this.$message.success("删除成功");
          bomMatchingInfo.splice(bomCategory, 1);
        });
      } else {
        bomMatchingInfo.splice(bomCategory, 1);
      }
    },
    addAcls(bomMatchingAcls, row, sort) {
      bomMatchingAcls.push({
        logical: "and",
        param: "额定电流",
        comparison: "==",
        data: 120,
        matchingId: row.id,
        executionorder: sort,
      });
    },
    deleteAcls(row, key) {
      row.splice(key, 1);
    },
    addParams(value, row, key, minValue) {
      if (value != null && parseInt(value) > parseInt(minValue)) {
        row.bomParamValueInfo.push({
          minValue: value,
        });
      } else if (value != null && parseInt(value) < parseInt(minValue)) {
        this.$message.warning("必须大于" + minValue);
      }
      // row.bomParamValueInfo.push();
    },
    setClass({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 2) {
        return "bom-category-cell";
      }
    },
    save() {
      let flag = false;
      this.dataList.forEach((item) => {
        if (item.name === "") {
          flag = true;
        }
      });
      if (flag) {
        this.$message.warning("元件分类名称不能为空！");
        return;
      }
      this.axios.post(BomCategory.saveBomCategory, this.dataList).then((data) => {
        this.$message.success("编辑成功");
      });
    },
    deleteCategory() {
      this.selectList = this.selectList.filter((obj1) => obj1.id !== null && obj1.id !== "");
      this.axios.post(BomCategory.deleteBomCategory, this.selectList).then((data) => {
        this.$message.success("删除成功");
        this.getBomCategory();
      });
    },
    addCategory() {
      this.dataList.push({
        name: "",
        matchingKw: "",
        bomMatchingInfo: [],
      });
    },
    handleSelectionChange(row) {
      this.selectList = row;
    },
    editCategory() {
      this.isEdit = true;
    },
    addMatchingKw(kws, row) {
      kws.push({
        name: row.name,
        isCount: 0,
        bomCategoryId: row.id,
      });
    },
    deleteMatchingKw(row, key) {
      row.splice(key, 1);
    },
  },
};
</script>

<style lang="scss">
.bom-category-cell.el-table__cell {
  padding: 0px 0px !important;
}
.bom-category-cell .cell {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>

<style></style>
